<div class="test3">
<nb-accordion >
    <nb-accordion-item >
      <nb-accordion-item-header >
        Spiele
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <ul style="list-style: none;">
            <li class="nav-li"><a  class="text" routerLink="/">Home</a></li> 
                <li class="nav-li"><a  class="text"routerLink="/tic-tac-toe">Tic-Tac-Toe</a></li>
                    <li class="nav-li"><a  class="text"routerLink="/vier-gewinnt">Vier-Gewinnt</a></li>
                        <li class="nav-li"><a  class="text"routerLink="/snake">Snake</a></li>
                            <li class="nav-li"><a  class="text"routerLink="/maze">Maze</a></li> 
        </ul>
      </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
</div>
<nb-actions id="test">
    
    <nb-action id="test2"><a routerLink="/">Home</a></nb-action>
    <nb-action id="test2"><a routerLink="/tic-tac-toe">Tic-Tac-Toe</a></nb-action>
    <nb-action id="test2"><a routerLink="/vier-gewinnt">Vier-Gewinnt</a></nb-action>
    <nb-action id="test2"><a routerLink="/snake">Snake</a></nb-action>
    <nb-action id="test2"><a routerLink="/maze">Maze</a></nb-action>
    
</nb-actions>





