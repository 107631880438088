<nb-layout>
  <nb-layout-header fixed>
    <app-nav></app-nav>
    
  </nb-layout-header>

  <nb-layout-column>
              <div>
                  <router-outlet></router-outlet>
              </div>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <a id="footerlink" href="https://lukas-roeding.de/" target="_blank">Portfolio</a>
    <a id="footerlink" href="../assets/Lebenslauf.docx" download>Lebenslauf</a>
  
  </nb-layout-footer>

</nb-layout>
