<div class="grid-container">

<div id="sketch-holder"><h1 *ngIf="score > 99">YOU WON!</h1><h1>Highscore: {{score}}</h1></div>
<div>
  <button class="button-large"(click)="up()" nbButton hero status="info" > up </button>
  <button (click)="left()" nbButton hero status="info" > left </button>
  <button (click)="right()" nbButton hero status="info" > right </button>
  <button class="button-large"(click)="down()" nbButton hero status="info" > down </button>
    <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            Spielregeln
          </nb-accordion-item-header>
          <nb-accordion-item-body id="regeln">
            Snake (englisch für Schlange) ist ein Computerspielklassiker, bei dem eine sich gerade oder rechtwinklig bewegende Schlange durch ein Spielfeld gesteuert wird. 
            Ziel des Spieles ist, die als Futter angebotenen zufällig erscheinenden "Happen" aufzunehmen und dem eigenen Schlangenkörper auszuweichen. 
            Während die Schlange mit jedem Happen wächst, wird das Manövrieren bei zunehmend vollerem Spielfeld – 
            und bei evtl. schnellerem Grundtakt – immer schwieriger. 
            Bei dieser Version von Snake wird die Schlange nach 10 gegessenen Happen breiter und schneller. Außerdem "stirbt" die Schlange, 
            wenn sie den Rand berührt. Die Schlange wird durch die Pfeiltasten der Tastatur gesteuert.
          </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion>
        <nb-accordion-item>
            <nb-accordion-item-header>
              Rules
            </nb-accordion-item-header>
            <nb-accordion-item-body id="regeln">
              Snake is the common name for a video game concept where the player maneuvers a line which grows in length, 
              with the line itself being a primary obstacle. The concept originated in the 1976 arcade game Blockade, 
              and the ease of implementing Snake has led to hundreds of versions 
              (some of which have the word snake or worm in the title) for many platforms. 
              After a variant was preloaded on Nokia mobile phones in 1998, 
              there was a resurgence of interest in the snake concept as it found a larger audience. 
              You move the snake by pressing the arrow-keys on your keyboard. If the snake hits the wall, or itself it shrinks back to its original size.
              By eating randomly appearing "food", the snake grows and so does your highscore.
            </nb-accordion-item-body>
          </nb-accordion-item>
      </nb-accordion>
</div></div>