<div class="grid-container">
    
    <div>

     
<h1>Spieler am Zug: {{player}}</h1>



<! wenn(ngIf) ein Spieler gewinnt, wird dieser Text angezeigt, sonst nicht>
<h2 *ngIf="winner">
    Spieler {{winner}} gewinnt!
</h2>
<main>
    <app-vier-gewinnt-square
        *ngFor="let val of squares; let i = index"
        [value]="val"
        (click)="makeMove(i)">
    </app-vier-gewinnt-square>
</main>
<button nbButton hero status="success" size="giant" (click)="newGame()">neues Spiel</button>

    </div>
    <div>
        <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>
                Spielregeln
              </nb-accordion-item-header>
              <nb-accordion-item-body id="regeln">
                Das Spiel wird auf einem senkrecht stehenden hohlen Spielbrett gespielt, 
                in das die Spieler abwechselnd ihre Spielsteine fallen lassen. 
                Das Spielbrett besteht aus sieben Spalten (senkrecht) und sechs Reihen (waagerecht). 
                Jeder Spieler besitzt 21 gleichfarbige Spielsteine. Wenn ein Spieler einen Spielstein in eine Spalte fallen lässt, 
                besetzt dieser den untersten freien Platz der Spalte. Gewinner ist der Spieler, der es als erster schafft, 
                vier oder mehr seiner Spielsteine waagerecht, senkrecht oder diagonal in eine Linie zu bringen. 
                Das Spiel endet unentschieden, wenn das Spielbrett komplett gefüllt ist, ohne dass ein Spieler eine Viererlinie gebildet hat. 
              </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <nb-accordion>
            <nb-accordion-item>
                <nb-accordion-item-header>
                  Rules
                </nb-accordion-item-header>
                <nb-accordion-item-body id="regeln">
                    The aim for both players is to make a straight line of four own pieces; the line can be vertical, horizontal or diagonal. 
                    Before starting, players decide randomly which of them will be the beginner; moves are made alternatively, one by turn.
                </nb-accordion-item-body>
              </nb-accordion-item>
          </nb-accordion>
    </div>
</div>

