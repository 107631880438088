<div class="grid-container">
    <div class="Bild">
<img id="background" src="../../assets/arcade.jpg">
    </div>
    <div>
      <h1 style="color: #00d68f;">Willkommen in der Arcadehall!</h1>
        <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>
                Was ist Arcadehall?
              </nb-accordion-item-header>
              <nb-accordion-item-body id="regeln">
                Arcadehall ist eine Spieleplatform, welche es dem Nutzer ermöglicht mehrere Spieleklassiker mit sich selbst, 
                oder einem Mitspieler zu spielen. Ich erschuf Arcadehall, um mich mit Angular vertraut zu machen 
                und um meine Java-/Typescript Fähigkeiten zu verbessern. 
              </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <nb-accordion>
            <nb-accordion-item>
                <nb-accordion-item-header>
                  What is Arcadehall?
                </nb-accordion-item-header>
                <nb-accordion-item-body id="regeln">
                    Arcadehall is a gamingplatform which allows the user to play severall gaming classics in singleplayer, or with another human.
                    The idea behind Arcadehall was to gain familiarity with Angular and to improve on my Java-/Typescipt skills.
                </nb-accordion-item-body>
              </nb-accordion-item>
          </nb-accordion>
    </div>
</div>

